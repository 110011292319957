// add sorting kay and value to array
export default function AddSortingNumberToButtonsSitebar(
  quickBarObject,
  buttonSitebar
) {
  return buttonSitebar.map((element) => ({
    ...element,
    sortNumber:
      element.name != "fullscreen" && element.name != "location"
        ? quickBarObject[element.name]
        : element.sortNumber,
  }));
}

// Remove teeoff marker and popup when players start playing

export default function RemoveTeeOff() {
  let checkPlaceTeeOff = false;
  map.eachLayer((layer) => {
    if (layer instanceof L.Marker) {
      if (
        layer.options.icon.options.className.search("icon-flights-start") !==
          -1 &&
        !checkPlaceTeeOff
      ) {
        if (map.hasLayer(layer)) {
          map.removeLayer(layer);
        }
      }
    }
  });
}

/**
 * Add data attribute to marker
 */
const DataMarkers = L.DivIcon.extend({
  createIcon: function (oldIcon) {
    let divElement = L.DivIcon.prototype.createIcon.call(this, oldIcon);

    if (this.options.data) {
      for (let key in this.options.data) {
        divElement.dataset[key] = this.options.data[key];
      }
    }
    return divElement;
  },
});

L.dataDivIcon = (options) => new L.DivIcon(options);

export default DataMarkers;

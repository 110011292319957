import AnalyticsTracking from "@helpers/analyticsTracking";

/**
 * Show player for bigsacreen
 * open popup
 */
export default function ShowPlayerPopupBigscreen(
  timePerPlayer,
  players,
  centerZoom,
  centerLatlng
) {
  let playersArray = players.split(",");
  let currentIndex = 0;

  // set zoom
  //   if (centerZoom) {
  //     map.setZoom(centerZoom);
  //   }

  // set center
  if (centerLatlng) {
    const [lat, lng] = centerLatlng.split(",").map(Number);
    map.setView([lat, lng], centerZoom);
  }

  function arrayIdx() {
    if (currentIndex >= playersArray.length) {
      currentIndex = 0;
    }
    return currentIndex++;
  }

  // first show popup
  openPopup(playersArray[arrayIdx()]);

  // show popup every timePerPlayer seconds * 1000
  setInterval(() => {
    openPopup(playersArray[arrayIdx()], centerZoom);
  }, timePerPlayer * 1000);
}

/**
 * Open popup (group flights)
 *
 * @param {Number} playerID
 */
function openPopup(playerID, centerZoom) {
  // console.log(playerID);
  let checkPlayer = false;
  map.eachLayer((layer) => {
    if (layer instanceof L.Marker) {
      const checkIfMarkerExistOnMap = L.DomUtil.hasClass(
        layer.options.icon.options,
        `player-${playerID}`
      );

      if (checkIfMarkerExistOnMap && !checkPlayer) {
        const { lat, lng } = layer._latlng;

        layer.openPopup();

        // map.flyTo([lat, lng], centerZoom);
        map.setView([lat, lng], centerZoom);
        layer._icon.classList.add("animation");

        AnalyticsTracking("clickGroup", {
          name: "flights",
          icon: "icon-flights",
          icon_shortcode: "",
          description: "",
          lat: Number(lat),
          lng: Number(lng),
          group_partner: HQ.Properties.appGroupPartner,
        });

        checkPlayer = true;
      }
    }
  });
}

/**
 * Show first layer icon-flights
 * open popup
 */

import { hasTouchScreen } from "@utils/function";

export default function ShowFirstLayerFlights() {
  // https://tree.taiga.io/project/eventhubhq-eventhubclient/issue/351
  // HQ.Properties.mode !== "iframe";
  if (hasTouchScreen()) return;

  let checkLayer = false;
  map.eachLayer((layer) => {
    if (layer instanceof L.Marker) {
      if (
        layer.options.icon.options.className?.search("icon-flights-start") !==
          -1 &&
        !checkLayer
      ) {
        layer.openPopup();
        checkLayer = true;
      }
    }
  });
}

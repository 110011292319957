/**
 * Get icon type depending on icon name
 *
 * @param {string} name
 * @param {string} description
 * @returns HTMLElement
 */
export const iconsType = (
  { icon, content, place, color, color_front, color_outline, players } = {
    color: "#000000",
  }
) => {
  const dataPlayer =
    icon === "icon-flights"
      ? `data-id-players="${players}" data-id-flightnumber-map="${content}"`
      : "";

  // global class for icon
  const classGlobal =
    "d-flex h-100 w-100 justify-content-center align-items-center rounded-circle fw-bold";

  // console.log(color_outline);
  const colorBackground = color ? ` background-color: ${color};` : "";
  const colorFront = color_front ? ` color: ${color_front};` : "";
  const colorOutline = color_outline
    ? ` border: 2px solid ${color_outline};`
    : "";

  const styleInlineForSVG = `style="${colorBackground}${colorFront}${colorOutline}"`;

  // icon HTML type
  const iconHTML = `<span class="${classGlobal}" ${styleInlineForSVG}>${content}</span>`;

  const iconColor = icon === "icon-flights" ? "#ffffff" : color_front;

  const iconFlightsFavorite =
    icon === "icon-flights"
      ? `<use href="#${icon}-fave" class="favorite"></use>`
      : "";

  const debugDependentIcons =
    HQ.Properties.locatorGroupsDebug != undefined && icon === "icon-flights"
      ? `<span style="color: #fff; font-size: 1rem;">${content}</span>`
      : `<svg fill="${iconColor || "#000"}">
        <use href="#${icon}" class="first"></use>
        ${iconFlightsFavorite}
      </svg>`;

  // icon SVG type
  const iconSVG = `
    <div ${dataPlayer} class="${classGlobal}" ${styleInlineForSVG}>
     ${debugDependentIcons} 
    </div>`;

  // set icon type
  const iconType =
    icon === "icon-restpoint" ||
    (icon === "icon-hole" && !place) ||
    icon === "icon-current"
      ? iconHTML
      : iconSVG;

  return iconType;
};

import { select } from "@utils/function";

export default function addZoomAtributei18n() {
  const object = { "zoom-in": "zoomIn", "zoom-out": "zoomOut" };

  Object.entries(object).forEach(([key, value]) => {
    const zoomTyp = select(`.leaflet-control-${key}`);
    zoomTyp.setAttribute("title", HQ.Properties.i18n[value].title);
  });
}

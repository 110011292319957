/**
 * Create results in popup
 */

import { createElement } from "@utils/function";

// 1. create head tabs
// 2. create row with players change the look of the
// results to match the mobile version

export default function ResultsTennis() {
  const tennisResults = HQ.Properties.results;

  const head = Object.entries(tennisResults)
    .map(([key, value]) => {
      if (value.length <= 0) return;
      return key;
    })
    .filter((key) => key !== undefined);

  console.log("head", head);
  const test = createHeaderResults(head);
  return test;
}

function createHeaderResults(Eventtypes) {
  const headerTabTennis = createElement("div");
  headerTabTennis.classList.add("header-tab");

  let eventtypeName;

  [...Eventtypes].forEach((element) => {
    switch (element) {
      // -------------------------
      // ATP
      case "MS":
        eventtypeName = HQ.Properties.i18n.tennisEventTypeName.MS;
        break;
      case "MD":
        eventtypeName = HQ.Properties.i18n.tennisEventTypeName.MD;
        break;
      case "QS":
        eventtypeName = HQ.Properties.i18n.tennisEventTypeName.QS;
        break;
      case "QD":
        eventtypeName = HQ.Properties.i18n.tennisEventTypeName.QD;
        break;
      // -------------------------
      // WTA
      case "LS":
        eventtypeName = HQ.Properties.i18n.tennisEventTypeName.LS;
        break;
      case "LD":
        eventtypeName = HQ.Properties.i18n.tennisEventTypeName.LD;
        break;
      case "RS":
        eventtypeName = HQ.Properties.i18n.tennisEventTypeName.RS;
        break;
      case "RD":
        eventtypeName = HQ.Properties.i18n.tennisEventTypeName.RD;
        break;

      default:
        break;
    }

    headerTabTennis.textContent = eventtypeName;
  });

  return headerTabTennis;
}

import {
  convertJSONToArray,
  createElement,
  select,
  svgIcon,
} from "@utils/function";

export default function ShowMultCourseLayers() {
  if (!HQ.Properties.isMultiCourse) return;

  const arrayObject = convertJSONToArray(HQ.Properties.courses);
  // console.log(HQ.Properties.courses["config"]);
  // console.log(HQ.Properties.courses[1]["config"]);

  (function addControlPlaceholders(map) {
    let corners = map._controlCorners;
    let l = "leaflet-";
    let container = map._controlContainer;

    function createCorner(vSide, hSide) {
      let className = l + vSide + " " + l + hSide;
      corners[vSide + hSide] = L.DomUtil.create("div", className, container);
    }

    createCorner("top", "center");
  })(map);

  const configs = { position: "topcenter", description: "COURSE LAYER" };

  L.Control.Search = L.Control.extend({
    options: {
      position: configs.position,
    },
    onAdd: function () {
      // const container = L.DomUtil.create("button", "multi-course-layers");
      // container.textContent = configs.description;

      const wrapperButtons = createElement("div");
      wrapperButtons.classList.add("multi-course-wrapper");
      wrapperButtons.classList.add("d-none");
      wrapperButtons.classList.add("d-lg-flex");

      const leafletCenter = select(".leaflet-center");

      // leafletCenter.insertAdjacentElement("beforeend", container);

      leafletCenter.insertAdjacentElement("beforeend", wrapperButtons);

      arrayObject.forEach((element) => {
        const { color } = element;

        const {
          name,
          locator_center_zoom_mobile,
          locator_center_zoom,
          locator_center_latlng_mobile,
          locator_center_latlng,
        } = JSON.parse(HQ.Properties.courses[element.id]["config"]);

        const buttonLayer = `
          <div style="--color-button-layer: ${color}" class="d-flex button-layer leaflet-control" data-zoom-mobile="${locator_center_zoom_mobile}" data-zoom="${locator_center_zoom}" data-latlng-mobile="${locator_center_latlng_mobile}" data-latlng="${locator_center_latlng}">
            <span class="text-truncate">${name}</span>
          </div>
        `;

        wrapperButtons.insertAdjacentHTML("afterbegin", buttonLayer);
      });

      return wrapperButtons;
    },
  });

  new L.Control.Search().addTo(map);
}

/**
 * Add cutom button (back-to-home) to map
 */
L.Control.ShowMultiCourseLayers = L.Control.extend({
  // button position
  options: {
    position: "topright",
  },

  // method
  onAdd: function (map) {
    const backToHome = L.DomUtil.create("a");
    backToHome.setAttribute("title", "SHOW");
    backToHome.role = "button";
    backToHome.href = "#";
    backToHome.innerHTML = svgIcon({ name: "icon-multi-course" });
    backToHome.className +=
      "d-lg-none leaflet-bar leaflet-control show-multi-course-layers";

    return backToHome;
  },
});

if (HQ.Properties.isMultiCourse) {
  new L.Control.ShowMultiCourseLayers().addTo(map);
}

import map from "@components/map";
import { selectAll } from "@utils/function";
import ShowPlacePopup from "@components/map/showPlacePopup";

export default function ActiveInformation({ dataTwitsID, clickType, partner }) {
  const dataChildren = selectAll(".information-row");

  [...dataChildren].forEach((child) => {
    const childrenArray = child.dataset.children.split(",");
    const dataLayerId = child.dataset.layerId;

    const check = childrenArray.includes(dataTwitsID);

    if (!check) return;

    const currentZoom = map.getZoom();

    ShowPlacePopup({
      placeID: dataTwitsID,
      idInformation: childrenArray[0],
      dataLayerId,
      currentZoom,
      clickType,
      partner,
    });
  });
}

import AddSortingNumberToButtonsSitebar from "./addSortingNumberToButtonsSitebar";
import { buttonQuickBar } from "@helpers/defaults";

export default function QuickBarConfig() {
  const quickBarObject = JSON.parse(HQ.Properties.quickBarConfig);

  // full screen add to array when
  // url has mode = iframe
  const fullScreenIn = {
    icon: "fullscreen-in",
    i18n: "fullscreenIn",
    title: "Fullscreen",
    name: "fullscreen",
    className: "fullscreen-content",
    sortNumber: Object.keys(quickBarObject).length + 15,
    show: true,
  };

  const buttonSitebar =
    HQ.Properties.mode == "iframe"
      ? [...buttonQuickBar, fullScreenIn]
      : buttonQuickBar;

  return AddSortingNumberToButtonsSitebar(quickBarObject, buttonSitebar)
    .filter((element) => element.sortNumber !== undefined)
    .sort((a, b) => a.sortNumber - b.sortNumber);
}

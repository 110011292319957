import RemoveHighlightsTwitterFromMap from "./removeHighlightsTwitterFromMap";
import CheckingIfLayerAddedToMap from "./checkingIfLayerAddedToMap";

export default function UpdateHighlightsTwits() {
  if (!HQ.Properties.highlights) return;

  // get video layer id from results
  // we needinformation about the id of the video layer
  const videoID = window["resultsPlaces"]
    .filter(({ icon }) => icon === "icon-video")
    .map(({ id }) => id);

  HQ.Properties.highlights.forEach(({ id, show_on_map }) => {
    CheckingIfLayerAddedToMap(videoID[0], id, show_on_map);
  });

  // remove twitter marker from map when
  // no data in HQ.Properties.highlights array
  RemoveHighlightsTwitterFromMap(videoID[0]);
}

/**
 * We remove all twitts that are not
 * added in the update HQ.Properties.highlights
 */
export default function RemoveHighlightsTwitterFromMap(videoID) {
  // console.log("videoID", videoID);
  // icon-video
  map.eachLayer((layer) => {
    if (layer instanceof L.Marker) {
      const classNameTwitter = layer.options.icon.options.className;
      if (classNameTwitter.search("icon-video") !== -1) {
        const arrayClassNames = classNameTwitter.split(/\b(\s+)/);
        const placeClass = arrayClassNames[arrayClassNames.length - 1];
        const idMarker = placeClass.split("-")[1];

        const findObjectInArray = HQ.Properties.highlights.find(
          (element) => element.id === idMarker
        );

        if (typeof findObjectInArray == undefined) {
          // remove makre from featureGroup
          window[`video-${videoID}`].removeLayer(layer);
        }
      }
    }
  });
}

import { select, selectAll } from "@utils/function";

export default function SwitchTeams(target) {
  const countryWrapperEur = select(".country-wrapper-eur");
  const countryWrapperUsa = select(".country-wrapper-usa");
  const team = selectAll(".team");

  team.forEach((el) => {
    el.classList.remove("is-active");
  });

  if (target.closest(".team-usa")) {
    target.classList.add("is-active");

    // USA
    countryWrapperUsa.classList.remove("d-none");
    countryWrapperUsa.classList.add("is-active");

    // EUR
    countryWrapperEur.classList.remove("is-active");
    countryWrapperEur.classList.add("d-none");
  }
  if (target.closest(".team-eur")) {
    target.classList.add("is-active");

    // EUR
    countryWrapperEur.classList.remove("d-none");
    countryWrapperEur.classList.add("is-active");

    // USA
    countryWrapperUsa.classList.remove("is-active");
    countryWrapperUsa.classList.add("d-none");
  }

  // const countryTeam = select(".country-team");
  // countryTeam.scrollIntoView({
  //   block: "start",
  //   behavior: "smooth",
  // });
}

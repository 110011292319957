import { defaults } from "@helpers/defaults";
import { hasTouchScreen } from "@utils/function";

let { zoom, zoom_mobile } = defaults.coordinates;

// azure maps it only uses integers otherwise there is an error

const zoomDesktop =
  defaults.baseMapUrl == "azuremaps" ? Math.round(zoom) : zoom;

const zoomMobile = zoom_mobile !== null ? zoom_mobile : zoom;

const ZoomOnMobileOrDesktop = hasTouchScreen()
  ? defaults.baseMapUrl == "azuremaps"
    ? Math.round(zoomMobile)
    : zoomMobile
  : zoomDesktop;

export default ZoomOnMobileOrDesktop;

import { additionalElementsAtTheBottomOfTheMap } from "@helpers/defaults";

export default function AddControlPlaceholders(map) {
  let corners = map._controlCorners;
  let l = "leaflet-";
  let container = map._controlContainer;

  function createAdditionalControl(vSide, hSide) {
    let className = l + vSide + " " + l + hSide;
    corners[vSide + hSide] = L.DomUtil.create("div", className, container);
  }

  createAdditionalControl("bottom", "right");
  createAdditionalControl("bottom", "left");
}

// --------------------------------------------------------------

additionalElementsAtTheBottomOfTheMap.forEach((item) => {
  if (HQ.Properties.showEventhubLogo != "1" && item.position == "bottomleft")
    return;
  L.Control.Search = L.Control.extend({
    options: {
      position: item.position,
    },
    onAdd: function () {
      const container = L.DomUtil.create("div", item.className);

      L.DomEvent.disableClickPropagation(container);

      container.insertAdjacentHTML("beforeend", item.icon);

      return container;
    },
  });

  new L.Control.Search().addTo(map);
});

// const leafletControlAttribution = select(".leaflet-control-attribution");
// console.log(leafletControlAttribution.offsetWidth);

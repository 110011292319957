import {
  convertJSONToArray,
  createElement,
  select,
  selectAll,
} from "@utils/function";

export default function CreatePlayerOnRange(
  popup,
  getPopupElement,
  playerOnRange
) {
  const leafletPopupContent =
    getPopupElement.querySelector(".card-leaflet").outerHTML;

  const dataRangeArea = playerOnRange?.dataset.rangeArea;

  const contentBody = createElement("div");
  contentBody.innerHTML = leafletPopupContent;

  const { countElement, htmlRowWidthPlayers } =
    UpdatePlayerOnRange(dataRangeArea);

  const countPlayersOnRange = countElement.length;

  const playerOnRangeContent = contentBody.querySelector(".player-on-range");
  const playerRangeRitle = contentBody.querySelector(".player-range-title");
  playerRangeRitle?.remove();
  playerOnRangeContent.textContent = "";

  if (countPlayersOnRange > 0) {
    playerOnRangeContent.insertAdjacentHTML(
      "beforebegin",
      `<div class="player-range-title pt-1 pb-2 mb-2 border-bottom">${HQ.Properties.i18n.playersOnThisArea}</div>`
    );

    playerOnRangeContent.insertAdjacentHTML("beforeend", htmlRowWidthPlayers);
  }

  popup.setContent(contentBody);
}

/**
 * Create html for popup player on range
 *
 * @param {String} dataRangeArea
 */
function UpdatePlayerOnRange(dataRangeArea) {
  const newArray = convertJSONToArray(HQ.Properties.players);

  let countElement = [];
  const htmlRowWidthPlayers = newArray
    .map((obj) => {
      return convertJSONToArray(obj)
        .map((value, index) => {
          if (value.isOnRangeArea !== dataRangeArea) return;

          let { country, image, firstname, lastname } = value;

          const countryIcon = country.toLowerCase();

          countElement.push(index);

          image = image
            ? `<img src="${image}">`
            : '<svg fill="#efefef"><use href="#icon-placeholder-man"></use></svg >';

          return `
            <div class="row mb-1">
              <div class="col-2 p-0 pb-1">
                <span title="${country}" class="flag-icon flag-icon-match flag-icon-${countryIcon}"></span>
              </div>
              <div class="col-2 p-0 pb-1">${image}</div>
              <div class="col-8 d-flex align-self-center">
                ${firstname} ${lastname}
              </div>
            </div>`;
        })
        .join("");
    })
    .join("");

  return { countElement, htmlRowWidthPlayers };
}

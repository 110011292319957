import { select } from "@utils/function";

// #88 Add event tracking to map
// https://www.rudderstack.com/docs/stream-sources/rudderstack-sdk-integration-guides/rudderstack-javascript-sdk/#track

/**
 *
 * @param {string} Captures the name of the tracked event
 * @param {object} Tracks the event properties
 */
export default function AnalyticsTracking(event, properties) {
  let eventName = "events";
  switch (event) {
    case "location":
      eventName = "location";
      break;
    case "zoomOut":
    case "zoomIn":
    case "centerView":
      eventName = "zoom";
    default:
      break;
  }

  const prop = properties
    ? {
        tournament_partner: HQ.Properties.appSponsorTournamentPartner,
        presenting_partner: HQ.Properties.appSponsorPresentingPartner,
        watch_partner: HQ.Properties.appSponsorWatchPartner,
        other_partner: HQ.Properties.appSponsorOtherPartner,
        app_type: HQ.Properties.appType,
        app_id: HQ.Properties.appId,
        channel_id: HQ.Properties.channelID,
        eventname: event,
        ...onSiteCheck(),
        ...properties,
      }
    : {
        tournament_partner: HQ.Properties.appSponsorTournamentPartner,
        presenting_partner: HQ.Properties.appSponsorPresentingPartner,
        watch_partner: HQ.Properties.appSponsorWatchPartner,
        other_partner: HQ.Properties.appSponsorOtherPartner,
        app_type: HQ.Properties.appType,
        app_id: HQ.Properties.appId,
        channel_id: HQ.Properties.channelID,
        ...onSiteCheck(),
        eventname: event,
      };

  try {
    rudderanalytics.track(eventName, prop, () => {
      // console.table({ eventType: event, properties: prop });
    });
  } catch (error) {}
}

function onSiteCheck() {
  const activeLoacation = select("[data-active-location]");
  const activeLocationOutsite = select("[data-active-location-outsite]");

  let onsite = "";
  let onsite_status = 3;
  if (activeLocationOutsite) {
    onsite = false;
    onsite_status = 2;
  } else if (!activeLocationOutsite && activeLoacation) {
    onsite = true;
    onsite_status = 1;
  }

  // https://tree.taiga.io/project/eventhubhq-eventhubclient/issue/452
  // onsite_status

  return { onsite, onsite_status };
}

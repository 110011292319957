import { select, createElement, svgIcon } from "@utils/function";

const closeButtonIcon = svgIcon({ className: "icon", name: "icon-close" });

function PopupCreate() {
  const popupInfo = createElement("div");
  popupInfo.classList.add("popup-info");
  document.body.insertAdjacentElement("beforeend", popupInfo);
}

/**
 * Create a popup with a message
 *
 * @param {string} text
 */
function PopupShow({ title, text, time = "", type }) {
  const popupInfo = select(".popup-info");

  popupInfo.textContent = "";

  const popupCloseButton = type
    ? `<button class="popup-close">${closeButtonIcon}</button>`
    : "";

  const template = `
    <div class="popup-content">
      <div class="popup-header">${HQ.Properties.i18n.location.maxBound.title}</div>
      <div class="popup-text">${HQ.Properties.i18n.location.maxBound.text}</div>
      ${popupCloseButton}
    </div>  
  `;

  // show popup
  document.body.classList.add("active-popup");

  popupInfo.insertAdjacentHTML("beforeend", template);

  // close popup after click on button
  ClosePopup(popupInfo);

  if (!time) return;

  // close popup after a timeout
  ClosePopupOnTimeout(popupInfo, time);
}

/**
 * Close popup
 */
function ClosePopup(popupInfo) {
  const popupClose = select(".popup-close");

  popupClose?.addEventListener("click", () => {
    document.body.classList.remove("active-popup");
    popupInfo.textContent = "";
  });
}

/**
 * Destroy popup
 */
function PopupDestroy() {
  const popupInfo = select(".popup-info");
  popupInfo.textContent = "";
  document.body.classList.remove("active-popup");
}

/**
 * Close popup after a timeout
 *
 * @param {HTMLElement} popupInfo
 * @param {number} time
 */
function ClosePopupOnTimeout(popupInfo, time) {
  alert(time);
  setTimeout(() => {
    document.body.classList.remove("active-popup");
    popupInfo.textContent = "";
  }, time);
}

/**
 * Universal popup
 *
 * template => html
 * width => number withoud without unit
 * time => number
 *
 * @param {object} template | width | time
 */
function PopupUniversal({ template, width, time }) {
  const popupInfo = select(".popup-info");
  popupInfo.setAttribute("style", width ? `max-width: ${width}px;` : "");
  popupInfo.textContent = "";

  const popupCloseButton = `<button class="popup-close">${closeButtonIcon}</button>`;

  const templateHTML = `
    <div class="popup-content">
      ${template}
      ${popupCloseButton}
    </div>  
  `;

  // show popup
  document.body.classList.add("active-popup");

  popupInfo.insertAdjacentHTML("beforeend", templateHTML);

  // close popup after click on button
  ClosePopup(popupInfo);

  if (!time) return;

  // close popup after a timeout
  ClosePopupOnTimeout(popupInfo, time);
}

export { PopupCreate, PopupShow, PopupUniversal, PopupDestroy };

// this function  only change the position (lat/lng)
// we do not change the data in the popup

export default function UpdatePlaces() {
  // console.log("HQ.Properties.places", HQ.Properties.places);

  HQ.Properties.places.forEach((place) => {
    const { id, latitude, longitude } = place;

    // we check if the coordinates exist and are not empty
    if (!latitude || !longitude) return;

    // we get the coordinates from the existing marker
    const marker = window[`marker-${id}`];
    if (!marker) return;
    const { lat, lng } = marker.getLatLng();

    // we check if the coordinates from the map and
    // those from the update place are different
    // if so, we change the position of the marker on the map
    if (lat !== Number(latitude || lng !== Number(longitude))) {
      const newLatLng = new L.LatLng(latitude, longitude);
      marker.setLatLng(newLatLng);
    }
  });
}

import { select } from "@utils/function";

export default function UpdateInformationLayerID(videoID, id) {
  const informationDataVideo = select(`div[data-layer-id="video-${videoID}"]`);
  const dataChildren = informationDataVideo.dataset.children;

  let addNewIDToChildrenInformation = dataChildren;
  if (!dataChildren.includes(id)) {
    addNewIDToChildrenInformation = dataChildren.concat(`,${id}`);
  }

  informationDataVideo.dataset.children = "";
  informationDataVideo.dataset.children = addNewIDToChildrenInformation;
}

import map from "@components/map";
import { defaults } from "@helpers/defaults";
import { hasTouchScreen } from "@utils/function";
import { setItemLocalStorage, select, svgIcon } from "@utils/function";

import ZoomOnMobileOrDesktop from "./zoomMobileOrDesktop";

import { PopupDestroy } from "@helpers/popup";
import AnalyticsTracking from "@helpers/analyticsTracking";

let { latlng, latlng_mobile } = defaults.coordinates;

latlng_mobile = latlng_mobile == null ? latlng : latlng_mobile;

const [lat, lng] = (hasTouchScreen() ? latlng_mobile : latlng)
  .split(",")
  .map(Number);

/**
 * Add cutom button (back-to-home) to map
 */
const BackToHomeButton = L.Control.extend({
  // button position
  options: {
    position: "topright",
  },

  // method
  onAdd: function (map) {
    const backToHome = L.DomUtil.create("a");
    backToHome.setAttribute("title", HQ.Properties.i18n.backToHome.title);
    backToHome.role = "button";
    backToHome.href = "#";
    backToHome.innerHTML = svgIcon({ name: "icon-center" });
    backToHome.className += "leaflet-bar back-to-home d-none";

    return backToHome;
  },
});

// --------------------------------------------------

/**
 * Get center of the map
 */
function GetCenterOfMap() {
  const backToHomeBtn = select(".back-to-home");
  const locationIsActive = select(".location-is-active");

  // show button
  backToHomeBtn.classList.remove("d-none");

  locationIsActive?.classList.remove("location-is-active");

  // destroy popup
  PopupDestroy();

  setItemLocalStorage("HQlocationWatching", false);
  // clicking on home-back set default view and zoom
  backToHomeBtn.addEventListener("click", BackToCenter);
}

function BackToCenter() {
  const backToHomeBtn = select(".back-to-home");

  AnalyticsTracking("centerView");

  // console.log("back to home");

  // if touch screen false

  // https://tree.taiga.io/project/eventhubhq-eventhubclient/issue/273
  // https://leafletjs.com/reference.html#map-setview

  map.setView([lat, lng], ZoomOnMobileOrDesktop);

  map.on("moveend", () => {
    const { lat: latCenter, lng: lngCenter } = map.getCenter();

    const latC = latCenter.toFixed(3) * 1;
    const lngC = lngCenter.toFixed(3) * 1;

    const defaultCoordinate = [
      Number(lat).toFixed(3) * 1,
      Number(lng).toFixed(3) * 1,
    ];

    const centerCoordinate = [latC, lngC];

    const compareToArrays = (a, b) => JSON.stringify(a) === JSON.stringify(b);

    if (compareToArrays(centerCoordinate, defaultCoordinate)) {
      backToHomeBtn.classList.add("d-none");
    }
  });

  backToHomeBtn.removeEventListener("click", BackToCenter);
}

export { GetCenterOfMap, BackToHomeButton };
